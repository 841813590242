.modalContainer {
  position: fixed;
  cursor: auto;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: black;
}

.modal {
  position: relative;
  height: 96%;
  overflow-y: auto;
  width: 75%;
  margin: auto;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  background-color: rgb(210, 197, 179);
}

.modal img {
  height: auto;
  position: relative;
  width: 100%;
  max-height: 50%;
  object-fit: contain;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.info {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 5px;
}

.title {
  font-style: italic;
}

.artist {
  text-decoration: underline;
}

.buttonClose {
  position: fixed;
  z-index: 10;
  top: 10px;
  right: 10px;
  background-color: #f6e5e5;
  border: none;
  border-radius: 20%;
  cursor: pointer;
  padding: 5px 10px;
}

@media screen and (min-width: 768px) {
  .modalContainer {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal {
    width: 600px;
    height: 90vh;
    margin-top: 5vh;
  }

  .info {
    margin-bottom: 40px;
  }

  .buttonClose {
    top: unset;
    right: unset;
    transform: translate(550px, 20px);
  }
}

.prevButton {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

.nextButton {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}
