.artworkListContainer {
  padding: 10px;
}

.artworkListContainer h2 {
  margin: 0;
  font-size: 4rem;
}

.artworkListContainer ul {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.artworkItem {
  cursor: pointer;
  margin-bottom: 20px;
  background-color: #f5f5f524;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.artworkItem img {
  height: auto;
  width: 100%;
  object-fit: contain;
  margin: auto;
}

.artworkItemContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.artworkItemTitle {
  margin-bottom: 10px;
}

.artworkItemArtist {
  margin-bottom: 10px;
}

.artworkItemDescription {
  margin-bottom: 10px;
}

.artworkItemContact {
  margin-top: 10px;
  font-style: italic;
}

.closeButton {
  margin-top: 10px;
  background-color: #f5f5f5;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.loading {
  font-size: large;
}

/* Media query for smaller screens */
@media (max-width: 568px) {
  .artworkListContainer ul {
    display: flex;
    flex-direction: column;
  }
}
